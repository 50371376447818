<template>
  <div>
    <div>
      <div class="menu-logo-mobile">
        <v-img :src="require('@/assets/jj.png')" contain />

        <div>
          <div class="header-menu-link" @click="pageClicked(contactData)">
            <v-img
              :src="require(`@/assets/contact_icon.png`)"
              class="menu-link-icon"
              contain
            />
          </div>
        </div>
      </div>

      <div class="mobile-menu-wrapper">
        <div class="retina-menu mobile-menu">
          <div class="menu-links">
            <div class="menu-text">
               Resources in <br/> Retinal Disease
            </div>
            <div
              v-for="page in pages"
              :key="page.id"
              class="menu-link-wrapper"
              :style="`min-width: 100%`"
              @click="pageClicked(page)"
            >
              <div class="menu-link">
                <div class="menu-link-icon">
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </div>
                <div class="menu-link-text" v-html="page.menutitle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup :pages="pages"></popup>
  </div>
</template>

<script>
import analytics from "../services/analytics";
export default {
  name: "Home",
  props: ["pages"],
  components: {
    Popup: () => import("@/views/Popup.vue")
  },
  // data: () => ({}),
  data: () => ({
    contactData: {
      id: "request",
      title: "Contact J&amp;J",
      componentName: "Contact",
      color: "#003a70",
      bgimage: "mission-banner.png",
      popup: "requestPopup",
      component: "request"
    }
  }),
  methods: {
    pageClicked(page) {
      analytics.page(page.id);
      this.$hub.$emit("startScreenSaverCheck");
      if (page.url) {
        window.open(page.url, "_blank");
      } else if (page.popup === "requestPopup") {
        this.$hub.$emit("requestPopup", page);
      } else {
        this.$router.push({ name: page.id });
        this.$hub.$emit("openPopup", page.id);
      }
    },
    returnClicked(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {
    window.$mob = this;
  }
};
</script>

<style lang="scss">
.menu-logo-mobile {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  background: white;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  height: 65px;
  & > a {
    width: 350px;
    margin: 16px;
    .v-image {
      max-width: 350px;
    }
  }
  & > div:first-of-type {
    margin: auto 0 auto auto;
    padding: 10px;
  }
}

.mobile-menu-wrapper {
  height: calc(100vh - 165px);
  overflow-y: auto;
  position: absolute;
  width: 100%;
  top: 65px;

  .header-menu-link {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 5px;
    }
    .menu-link-icon {
      width: 38px;
      height: 38px;
      display: inline-block;
    }
  }
  .mobile-menu {
    background-color: #81766f;
    height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
    overflow-y: auto;
    .menu-links {
      z-index: 2;
      position: relative;
      padding-top: 15px;
      margin: 10px;
      margin-top: 0;
      padding-bottom: 30px;
      .menu-link-wrapper {
        padding: 0;
        display: block;
        .menu-link {
          cursor: pointer;
          margin: 0;
          background-color: #ffffff;
          text-align: left;
          padding: 30px 0;
          margin-bottom: 8px;
          .menu-link-icon {
            background-color: #eb1600;
            height: 32px;
            width: 32px;
            line-height: 28px;
            text-align: center;
            color: white;
            margin-right: 16px;
            float: right;
            border-radius: 16px;
          }
          .menu-link-text {
            padding: 0 55px 0 16px;
            font-size: 18px;
            color: #090909;
            line-height: 115%;
            font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
          }
        }
      }
    }
    .menu-text-sm {
      font-size: 32px;
      color: white;
      line-height: 100%;
      font-family: "Johnson-Medium", Arial, Helvetica, sans-serif;
    }
    .menu-text {
      font-size: 60px;
      line-height: 100%;
      margin: -6px 0 16px;
      color: white;
      font-family: "Johnson-Medium", Arial, Helvetica, sans-serif;
    }
  }
}
@media (max-width: 450px) {
  .menu-logo-mobile {
    & > a {
      width: 200px;
      .v-image {
        max-width: 200px;
        margin-top: 8px;
      }
    }
  }
  .mobile-menu-wrapper .mobile-menu {
    .menu-text-sm {
      font-size: 30px;
      margin-bottom: 4px;
    }
    .menu-text {
      font-size: 48px;
    }
  }
}
</style>
